* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
}

html {
    font-size: 62.5%;
}




.main-nav {
    width: 100%;
    height: 7rem;


    background-color: #007bc4;
    color: white;
    position: sticky;
    display: grid;
    grid-template-columns: 15rem 1fr 1fr 10rem;

}

.input_search {
    text-align: right;
    padding-top: 1.4rem;

}

::placeholder {

    font-size: 1.6rem;
}

.input_search input[type=text] {
    padding: 10px 20px;
    border-radius: 4px;
}



.logo {

    grid-column: 2/3;
    justify-content: center;

}


.logo h2 {
    position: relative;
    top: 1.8rem;
    font-size: 3rem;
    font-weight: 500;

}

.container {
    display: grid;
    place-items: center;
    background-color: #041c2c;

    height: 93.3rem;
}

.container_data {
    position: relative;
    top: 5.8rem;
    height: 70rem;
    width: 100%;

    display: grid;
    padding: 0 31rem;
    grid-template-columns: repeat(6, 1fr);
    overflow-y: scroll;

}

.cards {
    overflow: hidden;
    width: 20rem;
    height: 26rem;
    background-color: #f6f6f6;
    margin: 1.4rem auto;
    border-radius: .5rem;
    transition: transform .4s;
    text-decoration: none;
    list-style-type: none;


}

.cards:hover {
    transform: scale(1.1);
    filter: drop-shadow(0.2rem 0.3rem 0.3rem grey);


}

img {
    height: 21rem;
    width: 100%;
}

.title {
    position: relative;

    top: 1.2rem;
    bottom: .3rem;
    display: inline-block;
    width: 100%;

    text-decoration: none;
}

.title h3 {

    text-decoration: none;
    text-align: center;

    color: #f9004d;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: bolder;

}

.stars_class {
    display: flex;
    gap: .4rem;
}



.show_details {

    border: 1px solid #003452;
    background-color: black;
    position: relative;
    width: 120rem;
    height: 60rem;
    display: flex;
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    overflow: hidden;
}

.poster_class img {
    width: 40rem;
    height: 40rem;
}

.details_class {
    width: 49rem;

    display: flex;
    flex-direction: column;
    gap: 2.7rem;

    min-height: 45rem;

}

.title_onlymovie {
    width: 50rem;
    position: relative;
    top: 2rem;
}

.title_onlymovie h4 {
    color: white;
    font-size: 3rem;
}

.parent_tgline_overview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.fa-star {
    color: #ffb100e8;
    font-size: 1.6rem;
}

.overview_class {
    line-height: 2.2rem;

    width: 40rem;
}

.overview_class p {
    color: white;
    font-size: 1.6rem;
}


.btn {
    padding: 8px 34px;
    background-color: #007bc4;
    color: white;
}

.btn_class {
    position: relative;
    top: 1.4rem;
}

.btn:hover {
    color: #007bc4;
    background-color: #003452;
    border: 1px solid #007bc4;
}



@media (max-width: 1912px) {
    .container_data {

        grid-template-columns: repeat(5, 1fr);


    }


}

@media (max-width: 1711px) {
    .container_data {

        grid-template-columns: repeat(4, 1fr);


    }

    .no_result_class {

        position: relative;
        left: 45rem;



    }

}

@media (max-width: 1488px) {
    .container_data {

        grid-template-columns: repeat(3, 1fr);


    }




}

@media (max-width: 1488px) {
    .poster_class img {
        width: 35rem;
        height: 35rem;
    }

    .show_details {

        border: 1px solid #003452;
        background-color: black;
        padding: 1rem 3rem;


    }

    .details_class {
        width: 40rem;



        max-height: 60rem;

    }

    .btn_class {
        position: relative;
        top: .5rem;
    }

}

@media (max-width: 1392px) {
    .details_class {



        max-height: 60rem;

    }



    .overview_class p {
        color: white;
        font-size: 1.4rem;
    }

    .show_details {

        padding: 1rem 50rem;
        width: 50%;


    }


}

@media (max-width: 1261px) {
    .details_class {}




    .container_data {

        grid-template-columns: repeat(2, 1fr);


    }

    .poster_class img {
        width: 34rem;
        height: 34rem;
    }



}

@media (max-width: 1067px) {
    .container_data {

        grid-template-columns: repeat(1, 1fr);

        padding: 2rem 25rem;
    }

    .main-nav {

        display: grid;
        grid-template-columns: 13rem 1fr 1fr 8rem;

    }

    .poster_class img {
        width: 32rem;
        height: 32rem;
    }

    .show_details {
        width: 40%;
        padding: 1rem 40rem;
    }




}

@media (max-width: 930px) {




    .details_class {
        width: 40rem;
    }

    .poster_class img {
        width: 30rem;
        height: 30rem;
    }

  
    .logo h2 {
        position: relative;
        top: 2rem;
        font-size: 2.7rem;
        font-weight: 500;

    }

    ::placeholder {

        font-size: 1.5rem;
    }

    .input_search input[type=text] {
        padding: 10px 15px;
        border-radius: 4px;
    }

    .main-nav {

        display: grid;
        grid-template-columns: 10rem 1fr 1fr 7rem;

    }
}

@media (max-width: 830px) {

    .show_details {
        width: 30%;
        padding: 1rem 20rem;
    }

    .poster_class img {
        max-width: 25rem;
        max-height: 26rem;
    }

    .main-nav {

        display: grid;
        grid-template-columns: 8rem 1fr 1fr 6rem;

    }

    .input_search input[type=text] {
        padding: 9px 13px;
        border-radius: 4px;
    }


}


@media (max-width: 806px) {
    .details_class {
        max-width: 34rem;
        flex-shrink: 2;
    }

    .overview_class {
        width: 34rem;

    }

    .show_details {
        
        height: 48rem;
        padding: 1rem 20rem;
       
       
        max-width: 35%;

    }

    .container_data {

        grid-template-columns: repeat(1, 1fr);

        padding: 2rem 20rem;
    }

    .poster_class img {
        flex-shrink: 2;
        max-width: 23rem;
        max-height: 24rem;
    }
}

@media (max-width: 712px) {

    .no_result_class {

        position: relative;
        left: 24rem;



    }

}



@media (max-width: 887px) {
    .details_class {
        width: 38rem;
    }

    .poster_class img {
        width: 28rem;
        height: 29rem;
    }

    .show_details {
        width: 50rem;
        padding: 1rem 40rem;
    }

    .overview_class {

        width: 38rem;
    }

}



@media (max-width: 774px) {
    .main-nav {

        display: grid;
        grid-template-columns: 7rem 1fr 1fr 5rem;

    }
}

@media (max-width: 718px) {
    .main-nav {

        display: flex;
        flex-direction: column;
        height: 15rem;
        align-items: center;
        gap: 3rem;
    }

    .input_search input[type=text] {
        padding: 8px 10px;
        border-radius: 6px;
    }

    .container_data {

        grid-template-columns: repeat(1, 1fr);

        padding: 0 0rem;
    }
}

@media (max-width: 621px) {
    .container_data {



        padding: 0 17rem;
    }
}

@media (max-width: 550px) {
    .container_data {



        padding: 0 14rem;
    }
}

@media (max-width: 480px) {
    .container_data {



        padding: 0 9rem;
    }



}

@media (max-width: 435px) {
    .container_data {



        padding: 0 7rem;
    }
}


@media (max-width: 402px) {
    .container_data {

        max-width: 100%;

        padding: 0 0rem;
    }

    .input_search input[type=text] {
        padding: 7px 6px;
        border-radius: 6px;
    }
}